<template>
  <section class="section section--404">
    <div class="container">
      <div class="filter">
        <div class="filter__cats-wrap">
          <!-- Category -->
          <div class="filter__wrap">
            <div class="filter__label">Category</div>
            <div v-for="item in categories" :key="item.name" class="filter__radio">
              <div class="filter__radio-row" @click="filterCat(item)">
                <div class="circle" :class="{'circle--active': category && category.name == item.name}">
                  <div
                    class="inner-circle"
                    :class="{'inner-circle--active': category && category.name == item.name}"
                  ></div>
                </div>
                <div class="filter__option">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <!-- delete -->
            <div v-if="category" class="filter__option filter__set">
              {{ category.name }}
              <span class="filter__remove" @click="openModal({cat: category})">
                <i class="ri-delete-bin-2-line"></i>
              </span>
            </div>
            <!-- add -->
            <div class="filter__add" @click="inputCat = true">
              <div class="filter__add-new">Add New</div>
            </div>
            <!-- add input -->
            <form @submit.prevent="addCat">
              <div v-if="inputCat">
                <label class="filter__input-label">
                  <input
                    v-model="newCat"
                    type="text"
                    class="filter__input filter__input--light"
                    placeholder="Add New"
                  />
                  <button class="icon-add"><i class="ri-add-circle-line"></i></button>
                  <button type="button" class="icon-close" @click="newCat = null">×</button>
                </label>
              </div>
            </form>
          </div>

          <!-- SUB Category -->
          <div class="filter__wrap">
            <div class="filter__label">SubCategory</div>
            <div v-for="item in subCats" :key="item.name" class="filter__radio">
              <div class="filter__radio-row" @click="filterSubCat(item)">
                <div class="circle" :class="{'circle--active': subCategory && subCategory.name == item.name}">
                  <div
                    class="inner-circle"
                    :class="{'inner-circle--active': subCategory && subCategory.name == item.name}"
                  ></div>
                </div>
                <div class="filter__option">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <!-- delete -->
            <div v-if="subCategory" class="filter__option filter__set">
              {{ subCategory.name }}
              <span class="filter__remove" @click="openModal({subCat: subCategory})">
                <i class="ri-delete-bin-2-line"></i>
              </span>
            </div>
            <!-- add -->
            <div v-if="category || newCat" class="filter__add" @click="inputSubCat = true">
              <div class="filter__add-new">Add New</div>
            </div>
            <!-- add input -->
            <form @submit.prevent="addSubCat">
              <div v-if="inputSubCat">
                <label class="filter__input-label">
                  <input
                    v-model="newSubCat"
                    type="text"
                    class="filter__input filter__input--light"
                    placeholder="Add New"
                  />
                  <button class="icon-add"><i class="ri-add-circle-line"></i></button>
                  <button type="button" class="icon-close" @click="newSubCat = null">×</button>
                </label>
              </div>
            </form>
          </div>

          <!-- SUB SUB Category -->
          <div class="filter__wrap">
            <div class="filter__label">SubSubCategory</div>
            <div v-for="item in subSubCats" :key="item.name" class="filter__radio">
              <div class="filter__radio-row" @click="filterSubSubCat(item)">
                <div class="circle" :class="{'circle--active': subSubCategory && subSubCategory.name == item.name}">
                  <div
                    class="inner-circle"
                    :class="{'inner-circle--active': subSubCategory && subSubCategory.name == item.name}"
                  ></div>
                </div>
                <div class="filter__option">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <!-- delete -->
            <div v-if="subSubCategory" class="filter__option filter__set">
              {{ subSubCategory.name }}
              <span class="filter__remove" @click="openModal({subSubCat: subSubCategory})">
                <i class="ri-delete-bin-2-line"></i>
              </span>
            </div>
            <!-- add -->
            <div v-if="(category && subCategory) || newSubCat" class="filter__add" @click="inputSubSubCat = true">
              <div class="filter__add-new">Add New</div>
            </div>
            <!-- add input -->
            <form @submit.prevent="addSubSubCat">
              <div v-if="inputSubSubCat">
                <label class="filter__input-label">
                  <input
                    v-model="newSubSubCat"
                    type="text"
                    class="filter__input filter__input--light"
                    placeholder="Add New"
                  />
                  <button class="icon-add"><i class="ri-add-circle-line"></i></button>
                  <button type="button" class="icon-close" @click="newSubSubCat = null">×</button>
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {required, requiredIf} from 'vuelidate/lib/validators'
import ModalCat from './ModalCat'
import _ from 'lodash'

export default {
  name: 'CategoryEdit',
  data() {
    return {
      product: null,
      productID: null,

      category: null,
      newCat: null,
      newSubCat: null,
      newSubSubCat: null,
      inputCat: false,
      inputSubCat: false,
      inputSubSubCat: false,

      subCategory: null,
      subCats: [],

      subSubCategory: null,
      subSubCats: [],

      filterParams: [],
      filterParam: null,
      newFilter: null,
      filters: [],
      newFilters: [],

      isLoading: false
    }
  },
  computed: {
    ...mapState({
      categories: s => s.categories.categories
    }),
    ...mapMutations({})
  },

  methods: {
    ...mapActions({
      addCategory: 'categories/addCategory'
      // delCategory: 'categories/delCategory'
    }),
    async filterCat(cat) {
      this.subCats = this.subSubCats = this.filterParams = []
      this.category = this.subCategory = this.subSubCategory = this.newCat = null
      this.category = cat
      this.subCats = this.category.items
      this.inputCat = this.inputSubCat = this.inputSubSubCat = false
    },
    filterSubCat(subCat) {
      this.filterParams = this.subSubCats = []
      this.subSubCategory = this.newSubCat = null
      this.subCategory = subCat
      this.subSubCats = this.subCategory.items
      this.inputSubCat = this.inputSubSubCat = false
    },
    filterSubSubCat(subSubCat) {
      this.filterParams = []
      this.newSubSubCat = null
      this.subSubCategory = subSubCat
      this.filterParams = this.subSubCategory.items
      this.inputSubSubCat = false
    },
    async addCat() {
      if (this.isLoading) return
      const newCat = {
        id: this.$getID(),
        name: this.newCat,
        items: []
      }
      try {
        this.isLoading = true
        await this.addCategory({cat: newCat})
        this.inputCat = false
        this.newCat = null
      } finally {
        this.isLoading = false
      }
    },
    async addSubCat() {
      if (this.isLoading) return
      const newSubCat = {
        id: this.$getID(),
        name: this.newSubCat,
        items: []
      }
      try {
        this.isLoading = true
        await this.addCategory({cat: this.category, subCat: newSubCat})
        this.inputSubCat = false
        this.newSubCat = null
      } finally {
        this.isLoading = false
      }
    },
    async addSubSubCat() {
      if (this.isLoading) return
      const newSubSubCat = {
        id: this.$getID(),
        name: this.newSubSubCat,
        items: []
      }
      try {
        this.isLoading = true
        await this.addCategory({cat: this.category, subCat: this.subCategory, subSubCat: newSubSubCat})
        this.inputSubSubCat = false
        this.newSubSubCat = null
      } finally {
        this.isLoading = false
      }
    },
    // async addFilter() {
    //   if (this.isLoading) return
    //   // const newFilter = this.newFilter

    //   try {
    //     this.isLoading = true
    //     await this.addCategory({
    //       cat: this.category,
    //       subCat: this.subCategory,
    //       subSubCat: this.SubSubCat,
    //       filter: this.newFilter // string
    //     })
    //     // this.inputSubSubCat = false
    //     this.newFilter = null
    //   } finally {
    //     this.isLoading = false
    //   }
    // },
    openModal({cat, subCat, subSubCat}) {
      const mobile = document.documentElement.clientWidth <= 700
      let modalHeight = mobile ? {height: '60%', width: '75%'} : {height: 'auto', maxHeight: 600, width: '50%'}
      this.$modal.display(
        ModalCat,
        {
          cat: cat || this.category,
          subCat: subCat || this.subCategory,
          subSubCat
        },
        {
          name: 'DeleteCatModal',
          transition: 'none',
          ...modalHeight,
          classes: 'popup',
          resizable: true
        }
      )
      this.category = this.subCategory = this.subSubCategory = null
      this.subCats = this.subSubCats = this.filterParams = []
    }
    //================================================================

    // async addSubCat() {
    //   this.category.items.push({
    //     id: this.category.items.length,
    //     name: this.newSubCat,
    //     items: []
    //   })
    //   await this.changeCategory(this.categories)
    // },
    // async addSubSubCat() {
    //   this.subCategory.items.push({
    //     id: this.subCategory.items.length,
    //     name: this.newSubSubCat,
    //     items: []
    //   })
    //   await this.changeCategory(this.categories)
    // },
    // async addFilter() {
    //   const newFilter = {
    //     id: this.filterParams.length,
    //     name: this.newFilter
    //   }
    //   this.filterParams.push(newFilter)
    //   await this.changeCategory(this.categories)
    // },
    // async removeFilter(id) {
    //   this.filterParams.splice(id, 1)
    //   await this.changeCategory(this.categories)
    // },
    // async delCat(cat) {
    //   const newCategories = _.cloneDeep(this.categories)

    //   newCategories.splice(this.categories.indexOf(cat), 1)
    //   console.log('newCats', newCategories)
    //   console.log(this.products)
    //   let delPr = this.products.filter(p => p.category == cat.name)
    //   console.log(delPr)

    //   let newPr = this.products.filter(p => p.category !== cat.name)
    //   console.log(newPr)

    //   this.subCats = this.subSubCats = this.filterParams = []
    //   this.category = this.subCategory = this.subSubCategory = this.newCat = null
    //   //  await this.fetch()
    //   await this.changeCategory(newCategories)
    // },
    // async delSubCat(subCat) {
    //   this.subCats.splice(this.subCats.indexOf(subCat), 1)
    //   this.filterParams = this.subSubCats = []
    //   this.subSubCategory = this.newSubCat = null
    //   await this.changeCategory(this.categories)
    // },
    // async delSubSubCat(subSubCat) {
    //   this.subSubCats.splice(this.subSubCats.indexOf(subSubCat), 1)
    //   this.filterParams = []
    //   this.newSubSubCat = null
    //   await this.changeCategory(this.categories)
    // }
  },
  validations: {
    category: {
      required: requiredIf(function () {
        if (!this.newCat) return true
      })
    },
    subCategory: {
      required: requiredIf(function () {
        if (!this.newSubCat) return true
      })
    },
    product: {required},
    productID: {required}
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import 'style';
</style>
