<template>
  <div class="popup__container">
    <!-- Add Loader for create modal and load data -->
    <h3 class="popup__title">Are you sure you want to delete:</h3>
    <p class="popup__item">
      <b>category: </b>
      <span v-if="subSubCat">{{ subSubCat.name }}</span>
      <span v-else-if="subCat && !subSubCat">{{ subCat.name }}</span>
      <span v-else>{{ cat.name }}</span>
    </p>
    <p class="popup__item">
      <b v-if="subSubCat">{{ subSubCat.name }}</b>
      <b v-else-if="subCat && !subSubCat">{{ subCat.name }}</b>
      <b v-else>{{ cat.name }}</b>
      contains
      <b>{{ products.length }}</b>
      items, which will be removed permanently.
    </p>
    <div class="popup__buttons">
      <div class="btn btn--bgr" @click="delCat()">Confirm</div>
      <div class="btn" @click="hideModal()">Discard</div>
    </div>
    <div class="popup__close" @click="hideModal()"><i class="ri-close-line"></i></div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import _ from 'lodash'
export default {
  props: {
    cat: {
      type: Object,
      default: null
    },
    subCat: {
      type: Object,
      default: null
    },
    subSubCat: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      products: s => s.admin.products.productsForDelete
    })
  },
  async created() {
    if (this.cat) this.setFilterCat(this.cat.name)
    if (this.subCat) this.setFilterSubCat(this.subCat.name)
    if (this.subSubCat) this.setFilterSubSubCat(this.subSubCat.name)
    await this.fetch()
  },
  methods: {
    ...mapActions({
      fetch: 'admin/products/fetchDelProducts',
      delCategory: 'categories/delCategory',
      delProducts: 'admin/products/delProducts'
    }),
    ...mapMutations({
      setFilterCat: 'admin/products/setFilterCat',
      setFilterSubCat: 'admin/products/setFilterSubCat',
      setFilterSubSubCat: 'admin/products/setFilterSubSubCat'
    }),
    async delCat() {
      if (this.isLoading) return
      try {
        this.isLoading = true
        if (this.products.length) await this.delProducts(this.products.map(p => p.productId))
        await this.delCategory()
      } finally {
        this.isLoading = false
        this.hideModal()
      }
    },
    hideModal() {
      this.setFilterCat(null)
      this.setFilterSubCat(null)
      this.setFilterSubSubCat(null)
      this.$modal.hide('DeleteCatModal')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
